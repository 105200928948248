.tooltip {
  :global {
    .ant-tooltip-arrow {
      &::before {
        background: var(--background-primary);
      }
    }

    .ant-tooltip-inner {
      background: var(--background-primary);
      padding: 16px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
      color: var(--on-surface-primary);
    }
  }
}

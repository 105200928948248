.links_container {
  flex-grow: 1;
}

.classes_links_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  padding-top: 8px;

  &_is_sidebar_collapsed {
    max-height: 0;
    margin-top: -8px;
  }
}

.aria_expanded {
  margin-top: -8px;
}

.link_chevron_button {
  height: 40px;
  margin-top: -8px;
  margin-bottom: -8px;
}

.link_chevron {
  transform: rotate(180deg);
  transition: .2s transform !important;
  width: 16px;

  &_is_open {
    transform: rotate(0deg);
  }
}

.header_class {
  height: 295px;
  padding: 24px;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  position: relative;
}

.button_class_management {
  position: absolute;
  top: 24px;
  right: 24px;
}

.titles_container {
  position: absolute;
  left: 26px;
  bottom: 46px;
  padding-right: 16px;
  min-width: 475px;

  * {
    color: var(--neutral-100);
    word-break: break-all;
  }
}

.illustration {
  background-image: url('../../assets/illustrations/mainPage.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 268px;
  display: flex;
  align-items: flex-end;

  &__title {
    width: min(717px, 100%) ;
    margin-left: 40px;
    margin-right: 24px;
    margin-bottom: 52px;
    color: #FFF;

    @media (max-width: 700px) {
      margin-left: 20px;
      margin-bottom: 26px;
    }
  }
}

.content_layout {
  padding: 24px;

  @media (max-width: 1000px) {
    padding: 12px;
  }
}

.select_card {
  margin-bottom: 24px;
}

.send_message_btn {
  width: fit-content;
}

.font-size-input {
  font-weight: bold;
  font-size: 14px;
  color: #777;
  border-radius: 5px;
  border-color: grey;
  height: 20px;
  padding: 2px 4px;
  text-align: center;
  width: 28px;
  align-self: center;
}

.font-size-input:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

button.font-decrement {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

button.font-increment {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}

.dropdown-icon-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  >svg {
    transform: scale(1.15) translateY(-1px);
  }
}

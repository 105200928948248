.buttons_container {
  margin-top: 8px;
}

.or_label {
  text-transform: uppercase;
  text-align: center;
}

.register_label {
  text-align: center;
}

.register_link {
  color: var(--text-accent);
  display: inline;
}

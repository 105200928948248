.icon_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: var(--surface-primary);
  color: var(--text-primary);
  border: 0;
  padding: 0;

  >span {
    height: 100%;

    display: inline-flex !important;
    align-items: center;
  }

  &_accent {
    color: var(--text-accent);

    &:hover {
      color: var(--text-accent) !important;
    }

    &.with_background {
      background: var(--surface-accent);

      &:hover {
        background: var(--surface-accent) !important;
        opacity: 0.85;
      }
    }
  }

  &_danger {
    color: var(--text-accent-red);

    &:hover {
      color: var(--text-accent-red) !important;
    }

    &.with_background {
      background: var(--surface-accent-red);

      &:hover {
        background: var(--surface-accent-red) !important;
        opacity: 0.85;
      }
    }
  }

  &_shape_circle {
    width: 36px !important;
    height: 36px;
    border-radius: 50%;
  }
}

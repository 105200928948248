@mixin light-theme-palette() {
  --text-primary: rgba(38, 51, 64, 1);
  --text-secondary: rgba(136, 140, 144, 1);
  --text-accent: rgba(0, 85, 174, 1);
  --text-accent-alt: rgba(107, 178, 238, 1);
  --text-accent-light: rgba(107, 178, 238, 1);
  --text-accent-red: rgba(203, 30, 71, 1);
  --text-accent-green: rgba(1, 100, 64, 1);
  --text-accent-purple: rgba(69, 0, 217, 1);
  --text-accent-orange: rgba(174, 63, 0, 1);
  --text-accent-black: rgba(23, 33, 43, 1);

  --background-primary: rgba(255, 255, 255, 1);
  --background-primary-alt: rgb(248, 248, 248);
  --background-secondary: rgba(244, 245, 246, 1);

  --surface-primary: rgba(251, 252, 254, 1);
  --surface-primary-alt: rgba(255, 255, 255, 1);
  --surface-secondary: rgba(229, 234, 242, 1);
  --surface-secondary-alt: rgba(222, 226, 235, 1);
  --surface-accent: rgba(236, 243, 249, 1);
  --surface-accent-alt: rgba(0, 85, 174, 1);
  --surface-accent-red: rgba(255, 233, 238, 1);
  --surface-accent-red-alt: rgba(241, 131, 157, 1);
  --surface-accent-green: rgba(235, 247, 244, 1);
  --surface-accent-green-alt: rgba(129, 192, 177, 1);
  --surface-accent-orange: rgba(249, 243, 236, 1);
  --surface-accent-purple: rgba(240, 236, 255, 1);
  --surface-accent-black: rgba(228, 235, 242, 1);

  --on-surface-primary: rgba(38, 51, 64, 1);
  --on-surface-secondary: rgba(134, 145, 168, 1);
  --on-surface-secondary-alt: rgba(229, 234, 242, 1);
  --on-surface-accent: rgba(0, 85, 174, 1);
  --on-surface-accent-alt: rgba(0, 85, 174, 1);
  --on-surface-accent-red: rgba(203, 30, 71, 1);
  --on-surface-accent-green: rgba(1, 100, 64, 1);
  --on-surface-accent-orange: rgba(174, 63, 0, 1);
  --on-surface-accent-purple: rgba(69, 0, 217, 1);
  --on-surface-accent-black: rgba(23, 33, 43, 1);
  --on-surface-accent-white: rgba(255, 255, 255, 1);
}

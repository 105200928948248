.rich-editor-ltr {
  text-align: left;
}
.rich-editor-rtl {
  text-align: right;
}
.rich-editor-paragraph {
  position: relative;
  margin-bottom: 8px !important;
  font-size: 16px;
  color: var(--text-primary) !important;
}
.rich-editor-quote {
  margin: 0;
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--text-secondary);
  border-left-color: var(--text-secondary);
  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 16px;
}
.rich-editor-h1 {
  font-size: 40px !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  color: var(--text-primary) !important;
}
.rich-editor-h2 {
  font-size: 32px !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  color: var(--text-primary) !important;
}
.rich-editor-h3 {
  font-size: 24px !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
  color: var(--text-primary) !important;
}
.rich-editor-indent {
  --lexical-indent-base-value: 40px;
}
.rich-editor-textBold {
  font-weight: bold;
}
.rich-editor-textItalic {
  font-style: italic;
}
.rich-editor-textUnderline {
  text-decoration: underline;
}
.rich-editor-textStrikethrough {
  text-decoration: line-through;
}
.rich-editor-textUnderlineStrikethrough {
  text-decoration: underline line-through;
}
.rich-editor-textSubscript {
  font-size: 0.8em;
  vertical-align: sub !important;
}
.rich-editor-textSuperscript {
  font-size: 0.8em;
  vertical-align: super;
}
.rich-editor-textCode {
  background-color: var(--surface-primary);
  padding: 1px 0.25rem;
  font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
  color: var(--text-primary);
}
.rich-editor-hashtag {
  background-color: rgba(88, 144, 255, 0.15);
  border-bottom: 1px solid rgba(88, 144, 255, 0.3);
}
.rich-editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}
.rich-editor-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.rich-editor-code {
  font-family: Menlo, Consolas, Monaco, monospace;
  display: block;
  padding: 8px 12px !important;
  line-height: 1.53;
  font-size: 14px;
  margin-bottom: 8px !important;
  overflow-x: auto;
  position: relative;
  tab-size: 2;
  color: var(--text-primary);
}
.editor .rich-editor-code {
  background: var(--surface-secondary-alt);
  border: 1px solid var(--surface-secondary-alt);
}

.rich-editor-tableScrollableWrapper {
  overflow-x: auto;
  margin: 0 25px 30px 0;
}
.rich-editor-tableScrollableWrapper > .rich-editor-table {
  /* Remove the table's margin and put it on the wrapper */
  margin: 0;
}
.rich-editor-table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow-y: scroll;
  overflow-x: scroll;
  table-layout: fixed;
  width: fit-content;
  margin: 0px 25px 30px 0px;
}
.rich-editor-tableRowStriping tr:nth-child(even) {
  background-color: #f2f5fb;
}
.rich-editor-tableSelection *::selection {
  background-color: transparent;
}
.rich-editor-tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.rich-editor-tableCell {
  border: 1px solid #bbb;
  width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  outline: none;
}
.rich-editor-tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.rich-editor-tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.rich-editor-tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.rich-editor-tableCellSelected {
  background-color: #c9dbf0;
}
.rich-editor-tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.rich-editor-tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.rich-editor-tableAddColumns {
  position: absolute;
  background-color: #eee;
  height: 100%;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.rich-editor-tableAddColumns:after {
  background-image: url(../icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.rich-editor-tableAddColumns:hover,
.rich-editor-tableAddRows:hover {
  background-color: #c9dbf0;
}
.rich-editor-tableAddRows {
  position: absolute;
  width: calc(100% - 25px);
  background-color: #eee;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.rich-editor-tableAddRows:after {
  background-image: url(../icons/plus.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rich-editor-tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.rich-editor-tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.rich-editor-tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.rich-editor-tableCellActionButton:hover {
  background-color: #ddd;
}
.rich-editor-characterLimit {
  display: inline;
  background-color: #ffbbbb !important;
}
.rich-editor-ol1 {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.rich-editor-ol2 {
  padding: 0;
  margin: 0;
  list-style-type: upper-alpha;
  list-style-position: outside;
}
.rich-editor-ol3 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: outside;
}
.rich-editor-ol4 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: outside;
}
.rich-editor-ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-roman;
  list-style-position: outside;
}
.rich-editor-ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
}
.rich-editor-listItem {
  margin: 0 32px;
}
.rich-editor-listItemChecked,
.rich-editor-listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.rich-editor-listItemChecked {
  text-decoration: line-through;
}
.rich-editor-listItemUnchecked:before,
.rich-editor-listItemChecked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.rich-editor-listItemUnchecked[dir='rtl']:before,
.rich-editor-listItemChecked[dir='rtl']:before {
  left: auto;
  right: 0;
}
.rich-editor-listItemUnchecked:focus:before,
.rich-editor-listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.rich-editor-listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.rich-editor-listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.rich-editor-listItemChecked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.rich-editor-nestedListItem {
  list-style-type: none;
}
.rich-editor-nestedListItem:before,
.rich-editor-nestedListItem:after {
  display: none;
}
.rich-editor-tokenComment {
  color: slategray;
}
.rich-editor-tokenPunctuation {
  color: #999;
}
.dark .rich-editor-tokenProperty {
  color: #ec67af;
}
.rich-editor-tokenProperty {
  color: #905;
}
.rich-editor-tokenSelector {
  color: #690;
}
.rich-editor-tokenOperator {
  color: #9a6e3a;
}
.rich-editor-tokenAttr {
  color: #07a;
}
.rich-editor-tokenVariable {
  color: #e90;
}
.rich-editor-tokenFunction {
  color: #dd4a68;
}
.rich-editor-mark {
  background: rgba(255, 212, 0, 0.14);
  border-bottom: 2px solid rgba(255, 212, 0, 0.3);
  padding-bottom: 2px;
}
.rich-editor-markOverlap {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.rich-editor-mark.selected {
  background: rgba(255, 212, 0, 0.5);
  border-bottom: 2px solid rgba(255, 212, 0, 1);
}
.rich-editor-markOverlap.selected {
  background: rgba(255, 212, 0, 0.7);
  border-bottom: 2px solid rgba(255, 212, 0, 0.7);
}
.rich-editor-embedBlock {
  user-select: none;
}
.rich-editor-embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}
.rich-editor-layoutContainer {
  display: grid;
  gap: 10px;
  margin: 10px 0;
}
.rich-editor-layoutItem {
  border: 1px dashed #ddd;
  padding: 8px 16px;
}
.rich-editor-autocomplete {
  color: #ccc;
}
.rich-editor-hr {
  padding: 2px 2px;
  border: none;
  margin: 1em 0;
  cursor: pointer;
}
.rich-editor-hr:after {
  content: '';
  display: block;
  height: 2px;
  background-color: #ccc;
  line-height: 2px;
}
.rich-editor-hr.selected {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.rich-editor-image {
  max-width: 100%;
}

.notification_container {
  padding: 12px 16px !important;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  width: 567px !important;

  :global {
    .ant-notification-notice-icon {
      display: none;
    }

    .ant-notification-notice-message {
      margin-inline-start: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.notification {
  &_success {
    color: var(--on-surface-accent-green);

    .notification__icon {
      &::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(7, 228, 149, 0.20) 0%, rgba(7, 228, 149, 0.00) 100%);
      }
    }

    > p {
      color: var(--on-surface-accent-green);
    }
  }

  &_error {
    color: var(--on-surface-accent-red);

    .notification__icon {
      &::before {
        background: radial-gradient(50% 50% at 50% 50%, rgba(203, 30, 71, 0.20) 0%, rgba(203, 30, 71, 0.00) 100%);
      }
    }

    > p {
      color: var(--on-surface-accent-red);
    }
  }

  &__icon {
    padding: 4px;
    height: 32px;
    position: relative;

    &::before {
      content: '';

      display: block;
      width: 212px;
      height: 212px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 8px;
  }
}

.title_wrapper {
  display: flex;
  flex-grow: 1;
}

.title {
  flex-grow: 1;
  margin: 0 !important;
}

.prefix {
  white-space: nowrap;
  margin: 0 !important;
}

.auth_page {
  min-height: 100dvh;
  background: var(--background-primary);

  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 32px 0;

    > div {
      margin: 0;
    }
  }

  &_register_mode {
    flex-direction: row-reverse;

    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .background {
      left: 16px;
    }
  }

  .background {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    max-height: 100dvh;

    @media (max-width: 1000px) {
      top: 40%;
    }
  }
}

.two_columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  >div:nth-child(1) {
    flex-grow: 1;
  }

  >div:nth-child(2) {
    flex-shrink: 0;
    width: fit-content;
  }

  @media (max-width: 1350px) {
    flex-direction: column;
    gap: 24px;

    >div:nth-child(2) {
      width: 100%;
    }
  }
}

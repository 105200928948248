.navbar_container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;

  padding: 8px 24px 0 24px;
  background: var(--background-primary);

  @media (max-width: 1000px) {
    padding: 8px 8px 0 8px;
  }
}

.navbar_item {
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: .2s color, .2s background-color;
  position: relative;
  color: var(--on-surface-primary);
  background: var(--background-primary);
  border-radius: 5px;

  &::before {
    content: "";

    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);

    width: 80%;
    height: 0;
    background-color: var(--on-surface-accent-alt);
    border-radius: 5px;
    transition-duration: .2s;
  }

  &_is_active {
    background-color: var(--surface-accent);
    color: var(--on-surface-accent);

    &::before {
      height: 2px;
    }

    p {
      color: var(--on-surface-accent);
    }
  }

  &:hover {
    background-color: var(--surface-accent);
  }
}

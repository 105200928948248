.description {
  margin-top: 43px;
  margin-right: 29px;
  margin-left: 29px;
  width: 668px;
  height: 90dvh;
  position: relative;
  padding: 29px 25px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin-left: 12px;
    margin-right: 12px;
  }

  @media (max-width: 700px) {
    width: 90%;
    height: fit-content;
    padding-top: 70px;
    gap: 24px;
  }

  &__folder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__title_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    color: #FFF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;

    position: relative;
    margin-bottom: 0;
    
    @media (max-height: 850px) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__item {
    height: 350px;
    position: relative;
    padding: 0 38px;

    @media (max-width: 800px) {
      height: fit-content;
    }

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__slide_buttons_container {
      position: absolute;
      top: 0;
      left: 53%;

      display: flex;
      gap: 19px;
    }

    &__slide_button {
      display: inline-flex;
      padding: 12px 16px;
      align-items: center;
      gap: 8px;
      border-radius: 5px;
      background: var(--surface-accent);

      svg {
        color: var(--text-primary);
      }
    }

    &__header {
      color: var(--text-primary);
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      margin-top: 135px;
      margin-bottom: 31px;
      position: relative;
    }

    &__text {
      color: var(--text-primary);
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      position: relative;
    }
  }
}

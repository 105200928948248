.logo_wrapper {
  position: relative;
  min-height: 142px;
  margin-top: 40px;
}

.logo_spines {
  position: absolute;
  top: 6px;
  left: 52px;

  transition: .2s top, .2s left, .2s transform;
  transform-origin: center;
  cursor: pointer;

  &_is_sidebar_collapsed {
    transform: scale(0.3);
    left: -30px;
  }
}

.logo_text {
  position: absolute;
  top: 82px;
  left: 16px;
  cursor: pointer;
}

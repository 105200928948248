.paragraph {
  font-size: 16px;
  line-height: 112.5%;
  font-weight: 400;
  color: var(--text-primary);
  margin-bottom: 0;
  position: relative;

  &_weight_medium {
    font-weight: 500;
    line-height: 100%;
  }

  &_variant_footnote_large {
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
  }

  &_variant_footnote_small {
    font-size: 12px;
    font-weight: 500;
    line-height: 100%;
  }

  &_weight_bold {
    font-weight: 600;
    line-height: 100%;
  }

  &_color_secondary {
    color: var(--text-secondary)
  }

  &_color_accent {
    color: var(--text-accent);
  }

  &_color_success {
    color: var(--text-accent-green);
  }

  &_color_error {
    color: var(--text-accent-red);
  }
}

.paragraph_button {
  display: flex;
  align-items: center;
  color: var(--text-accent);
}

.hidden {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 24px;
    width: 100%;

    border-radius: 5px;
    background: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(40px);
  }
}

.toggles_container {
  margin-top: 24px;
}

.tooltip {
  &__inner_content_wrapper {
    width: 168px;
    padding: 8px;
    transition: .2s background-color;
    border-radius: 4px;
    cursor: pointer;
    margin: -8px;

    &:hover {
      background-color: var(--surface-accent);
    }
  }

  &__footnote {
    color: var(--on-surface-secondary);
  }
}

.chevron_right {
  transform: rotate(180deg);
}

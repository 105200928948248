.title {
  font-weight: 600;
  line-height: 100%;
  color: var(--text-primary);
  margin-bottom: 0;

  &_level_1 {
    font-size: 40px
  }

  &_level_2 {
    font-size: 32px;
  }

  &_level_3 {
    font-size: 24px;
  }

  &_level_4 {
    font-size: 20px;
    line-height: 20px;
  }
}

.buttons_container {
  margin-top: 32px;
}

.auth_button {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  background: var(--surface-accent);
  border: 1px solid transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: .2s border-color;

  color: var(--on-surface-accent);
  font-size: 18px;
  font-weight: 600;

  &:hover {
    border-color: var(--on-surface-accent);
  }
}

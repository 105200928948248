@use 'theme/colors/base-palette' as *;
@use 'theme/colors/light-theme-palette' as *;
@use 'theme/colors/dark-theme-palette' as *;
@use 'theme/dimens' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Manrope';
  src: url(../public/font/Manrope.ttf);
}

body {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include base-palette();
  @include light-theme-palette();
  @include dimens();

  &.dark {
    @include dark-theme-palette();
  }
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

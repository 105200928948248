.auth_form {
  display: flex;
  width: 446px;
  flex-direction: column;
  margin-top: 104px;
  margin-left: 58px;
  margin-right: 58px;
  position: relative;
  
  @media (max-height: 1000px) {
    margin-top: 40px;
  }
  
  @media (max-width: 1200px) {
    margin-left: 24px;
    margin-right: 24px;
  }
}

.auth_logo {
  height: 151px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.auth_title {
  color: var(--text-primary);
  font-size: 48px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 40px;
  white-space: nowrap;
}

@mixin base-palette() {
  --primary-100: rgba(236, 243, 249, 1);
  --primary-150: rgba(228, 235, 242, 1);
  --primary-200: rgba(227, 241, 255, 1);
  --primary-250: rgba(207, 233, 255, 1);
  --primary-300: rgba(107, 178, 238, 1);
  --primary-400: rgba(112, 170, 232, 1);
  --primary-500: rgba(0, 85, 174, 1);
  --primary-600: rgba(27, 68, 111, 1);
  --primary-700: rgba(38, 51, 64, 1);

  --secondary-100: rgba(238, 242, 250, 1);
  --secondary-200: rgba(229, 234, 242, 1);
  --secondary-250: rgba(222, 226, 235, 1);
  --secondary-300: rgba(180, 189, 206, 1);
  --secondary-400: rgba(134, 145, 168, 1);
  --secondary-500: rgba(71, 80, 98, 1);
  --secondary-550: rgba(37, 45, 57, 1);
  --secondary-600: rgba(27, 37, 51, 1);
  --secondary-700: rgba(23, 33, 43, 1);

  --neutral-100: rgba(255, 255, 255, 1);
  --neutral-200: rgba(242, 244, 248, 1);

  --accent-red-200: rgba(255, 233, 238, 1);
  --accent-red-300: rgba(254, 203, 214, 1);
  --accent-red-400: rgba(241, 131, 157, 1);
  --accent-red-500: rgba(203, 30, 71, 1);
  --accent-red-600: rgba(147, 48, 70, 1);
  --accent-red-650-opacity-55: rgba(162, 32, 63, 0.55);

  --accent-green-200: rgba(235, 247, 244, 1);
  --accent-green-400: rgba(129, 192, 177, 1);
  --accent-green-500: rgba(1, 100, 64, 1);
  --accent-green-600: rgba(42, 98, 84, 1);

  --accent-orange-200: rgba(249, 243, 236, 1);
  --accent-orange-300: rgba(255, 225, 203, 1);
  --accent-orange-400: rgba(250, 194, 162, 1);
  --accent-orange-450-opacity-60: rgba(250, 126, 73, 0.6);
  --accent-orange-500: rgba(174, 63, 0, 1);
  --accent-orange-600: rgba(163, 89, 57, 1);

  --accent-purple-200: rgba(240, 236, 255, 1);
  --accent-purple-400: rgba(210, 189, 255, 1);
  --accent-purple-500: rgba(69, 0, 217, 1);
  --accent-purple-600: rgba(90, 69, 167, 1);
}

.content_layout {
  padding: 24px;
  max-width: 1400px;
  min-height: 100dvh;

  @media (max-width: 1000px) {
    padding: 12px;
  }

  &_with_class_header {
    min-height: 100%;
  }
}

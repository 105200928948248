.editable_table {
  .header-row {
    background-color: var(--secondary-300);

    .sql-table-add-column-cell {
      background-color: var(--secondary-300);
    }

    .ant-table-cell {
      padding: 8px 16px;
    }
  }

  .sql-table-add-column-cell  {
    max-width: 70px !important;
    width: 70px !important;
    flex: unset !important;
    flex-grow: 0;
    flex-shrink: 1;
  }

  .ant-table-row > .ant-table-cell {
    flex-grow: 1 !important;

    >p {
      margin-bottom: 0;
    }
  }

  .ant-table-footer {
    padding: 8px;
  }
}

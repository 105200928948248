@import '~theme/mixins/no-scrollbar';

.sidebar {
  transition: .2s max-width;
  max-width: 316px;
  min-height: 100vh;
  background-color: var(--background-primary);

  position: sticky !important;
  top: 0;

  flex-shrink: 0;
  z-index: 3;

  &_with_shadow {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }

  &__inner_container {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: relative;
  }

  &__collapse_button {
    position: absolute;
    top: 16px;
    right: 13px;

    &__icon {
      transition: .2s transform;
      color: var(--text-secondary);

      &_is_sidebar_collapsed {
        transform: rotate(180deg);
      }
    }
  }
}

.content_container {
  padding: 0 16px 32px 16px;
  flex-grow: 1;

  transition: .2s padding;

  &_is_sidebar_collapsed {
    padding: 0 3px 32px 3px;
  }
}

.page_wrapper {
  width: calc(100% - 316px);
  min-height: 100dvh;
  background: var(--background-secondary);
  flex-grow: 1;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &_primary_bg {
    background: var(--background-primary);
  }
}

.content_wrapper {
  z-index: 1;
  position: relative;
}

@mixin dark-theme-palette() {
  --text-primary: rgba(236, 243, 249, 1);
  --text-secondary: rgba(180, 189, 206, 1);
  --text-accent: rgba(107, 178, 238, 1);
  --text-accent-alt: rgb(65, 110, 147);
  --text-accent-light: rgba(107, 178, 238, 1);
  --text-accent-red: rgba(241, 131, 157, 1);
  --text-accent-green: rgba(129, 192, 177, 1);
  --text-accent-purple: rgba(210, 189, 255, 1);
  --text-accent-orange: rgba(250, 194, 162, 1);
  --text-accent-black: rgba(236, 243, 249, 1);

  --background-primary: rgba(27, 39, 51, 1);
  --background-primary-alt: rgba(37, 45, 57, 1);
  --background-secondary: rgba(23, 33, 43, 1);

  --surface-primary: rgba(27, 37, 51, 1);
  --surface-primary-alt: rgba(37, 45, 57, 1);
  --surface-secondary: rgba(71, 80, 98, 1);
  --surface-secondary-alt: rgba(71, 80, 98, 1);
  --surface-accent: rgba(27, 68, 111, 1);
  --surface-accent-alt: rgba(207, 233, 255, 1);
  --surface-accent-red: rgba(162, 32, 63, 0.55);
  --surface-accent-red-alt: rgba(207, 80, 111, 0.85);
  --surface-accent-green: rgba(42, 98, 84, 1);
  --surface-accent-green-alt: rgba(55, 153, 129, 0.85);
  --surface-accent-orange: rgba(250, 126, 73, 0.6);
  --surface-accent-purple: rgba(90, 69, 167, 1);
  --surface-accent-black: rgba(27, 37, 51, 1);

  --on-surface-primary: rgba(236, 243, 249, 1);
  --on-surface-secondary: rgba(180, 189, 206, 1);
  --on-surface-secondary-alt: rgba(71, 80, 98, 1);
  --on-surface-accent: rgba(207, 233, 255, 1);
  --on-surface-accent-alt: rgba(27, 68, 111, 1);
  --on-surface-accent-white: rgba(0, 85, 174, 1);
  --on-surface-accent-red: rgba(254, 203, 214, 1);
  --on-surface-accent-green: rgba(235, 247, 244, 1);
  --on-surface-accent-orange: rgba(255, 225, 203, 1);
  --on-surface-accent-purple: rgba(240, 236, 255, 1);
  --on-surface-accent-black: rgba(236, 243, 249, 1);
}

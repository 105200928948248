.code_editor {
  width: 100% !important;
  border-radius: 16px;
  background-color: var(--background-secondary);

  :global(.ace_gutter) {
    background-color: var(--background-secondary);
    border: none;
  }

  :global(.ace_gutter-cell) {
    color: var(--text-secondary);
    padding-left: 24px;
  }

  :global {
    .ace_keyword,
    .ace_meta,
    .ace_storage,
    .ace_storage.ace_type,
    .ace_support.ace_type {
      color: #d15704;
    }
  }
}

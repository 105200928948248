.link_router {
  text-decoration: none;
  color: var(--text-primary);

  &_with_hover {
    transition: .2s opacity;

    &:hover {
      opacity: 0.6;
    }
  }

  &_color_accent {
    color: var(--text-accent);
  }
}

.link_sidebar {
  padding: 8px;
  transition: .2s background-color, .2s width, .2s margin;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  color: var(--text-primary);

  p {
    transition: .2s color;
  }

  svg {
    position: relative;
    left: 0;
    transition: .2s left;
  }

  &:hover {
    background-color: var(--surface-accent);
    color: var(--on-surface-accent);

    p {
      color: var(--on-surface-accent)
    }
  }

  &_is_active {
    background-color: var(--surface-accent);
    color: var(--on-surface-accent);

    p {
      color: var(--on-surface-accent);
    }
  }

  &_is_sidebar_collapsed {
    width: 40px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

.input {
  height: 40px;
  background-color: var(--surface-secondary);
  border-radius: 5px;
  border: 1px solid transparent;
  transition: .2s border-color;
  padding: 8px 16px;

  font-size: 16px;
  line-height: 16px;
  color: var(--on-surface-primary);

  &:hover {
    border: 1px solid var(--on-surface-secondary);
    background-color: var(--surface-secondary);
  }

  &:active, &:focus {
    border: 1px solid var(--on-surface-secondary);
    background-color: var(--surface-secondary);
  }

  &::placeholder {
    color: var(--on-surface-secondary);
  }

  /* for inputs with the type 'password' */
  > input {
    &::placeholder {
      color: var(--on-surface-secondary);
    }
  }

  svg {
    color: var(--on-surface-primary);
  }

  &_error {
    border-color: var(--on-surface-accent-red) !important;
  }
}

:global(.ant-input-affix-wrapper-focused) {
  border: 1px solid var(--on-surface-secondary) !important;
  background-color: var(--surface-secondary) !important;
  box-shadow: none !important;
}

:global(.ant-input-prefix) {
  margin-inline-end: 8px !important;

  svg {
    color: var(--on-surface-primary);
  }
}

.dropdown_container {
  position: relative;
  z-index: 100;
}

.dropdown {
  position: absolute;
  bottom: -4px;
  left: 0;
  transform: translateY(100%);

  width: 100%;
  background: var(--background-primary);
  border-radius: 5px;
  transition: .2s padding, .2s border;
  z-index: 100;

  &_is_open {
    padding: 8px;
    border: 1px solid var(--on-surface-secondary);
  }
}

.error_message {
  padding-top: 8px;
  padding-bottom: 2px;
}

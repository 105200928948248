.button {
  background-color: var(--surface-secondary);
  height: 40px;
  padding: 0 16px;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: .2s border-color;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;

  svg, p, span {
    color: var(--on-surface-primary);
  }

  &:hover {
    border: 1px solid var(--on-surface-primary) !important;
    background-color: var(--surface-secondary) !important;
  }

  &_type_accent {
    background-color: var(--surface-accent);

    svg, p, span {
      color: var(--on-surface-accent);
    }

    &:hover {
      border: 1px solid var(--on-surface-accent) !important;
      background-color: var(--surface-accent) !important;
    }

    &:disabled {
      background-color: var(--surface-accent);

      svg, p, span {
        color: var(--text-accent-light);
      }
    }
  }

  &_type_success {
    background-color: var(--surface-accent-green);
    color: var(--on-surface-accent-green);

    svg, p, span {
      color: var(--on-surface-accent-green);
    }

    &:hover {
      background-color: var(--surface-accent-green) !important;
      border: 1px solid var(--on-surface-accent-green) !important;
    }
  }

  &_type_error {
    background-color: var(--surface-accent-red);
    color: var(--on-surface-accent-red);

    svg, p, span {
      color: var(--on-surface-accent-red);
    }

    &:hover {
      background-color: var(--surface-accent-red) !important;
      border: 1px solid var(--on-surface-accent-red) !important;
    }
  }

  &_type_purple {
    background-color: var(--surface-accent-purple);
    color: var(--on-surface-accent-purple);

    svg, p, span {
      color: var(--on-surface-accent-purple);
    }

    &:hover {
      background-color: var(--surface-accent-purple) !important;
      border: 1px solid var(--on-surface-accent-purple) !important;
    }
  }

  &_type_accent_alt {
    background-color: var(--surface-accent-alt);
    color: var(--on-surface-accent-white);
    transition: .2s opacity;

    svg, p, span {
      color: var(--on-surface-accent-white);
    }

    &:hover {
      background-color: var(--surface-accent-alt) !important;
      opacity: 0.8;
    }
  }

  &_type_ghost {
    background: none;
    border: none !important;
    transition: .2s background-color;

    svg, p, span {
      color: var(--text-accent);
    }

    &:hover {
      border: none !important;
    }

    &:active {
      border: none !important;
    }
  }

  &_type_no_bg {
    color: var(--text-accent);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;

    &_disabled {
      cursor: default;
      color: var(--text-accent-alt);
    }

    &_success {
      color: var(--on-surface-accent-green);

      svg, p, span {
        color: var(--on-surface-accent-green);
      }
    }

    &_error {
      color: var(--on-surface-accent-red);

      svg, p, span {
        color: var(--on-surface-accent-red);
      }
    }
  }

  :global {
    .ant-btn-icon {
      display: flex;
      align-items: center;
    }
  }
}

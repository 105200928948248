@import "~theme/mixins/line-clamp";

.link_class_sidebar {
  padding: 0 8px;
  height: 32px;
  cursor: pointer;

  > * {
    transition: .2s color;
  }

  &:hover > * {
    color: var(--text-accent);
  }

  &_is_active {
    > * {
      color: var(--text-accent);
    }
  }
}

.class_name {
  @include line-clamp(1);
  line-height: 120%;
}

.chevron_icon {
  flex-shrink: 0;
  transition: .2s transform;
  transform-origin: center;
  color: var(--text-primary);
  width: 16px;

  &_is_not_collapsed {
    transform: rotate(180deg);
  }
}

.class_inner_link {
  padding: 8px;
  display: block;

  transition: .2s color, .2s font-weight;

  &:hover > * {
    color: var(--text-accent);
  }

  &_is_active {
    > * {
      font-weight: 600;
      color: var(--text-accent);
    }
  }
}

.user_section {
  margin-top: 16px;
  transition: .2s width, .2s left;
  position: relative;
  left: 0;

  &_is_sidebar_collapsed {
    width: fit-content;
    left: 13px;
  }
}

.link {
  transition: .2s opacity;
  color: var(--text-primary);

  &:hover {
    opacity: 0.7;
  }
}

.link_danger {
  > p {
    color: var(--text-accent-red);
  }
}
